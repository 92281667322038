export default function NoFoundComponent() {
  return (
    <div className="w-full h-screen bg-primary flex justify-center items-center">
      <div className="text-white text-center">
        <div className="text-[80px] font-bold">404</div>
        <div className="text-md">Page not found</div>
      </div>
    </div>
  );
}
