export const ProductData = [
  {
    images: "1.JPG",
    header: "Decking",
    description:
      "Enhance your outdoor space with our premium decking solutions. Our high-quality decking boards are designed for durability, beauty, and low maintenance. Choose from a variety of materials, including natural wood and composite options, each offering unique benefits such as resistance to weather, pests, and fading. Perfect for creating a stylish and functional area for relaxation, entertainment, or outdoor dining.",
    url: "/products",
  },
  {
    images: "18.png",
    header: "Fenching",
    description:
      "Secure and beautify your property with our top-quality fencing solutions. Our fences are crafted from durable materials like wood, vinyl, and metal, ensuring long-lasting protection and aesthetic appeal. Available in various styles and designs, our fences provide privacy, security, and a stylish border for your yard or garden. Easy to install and maintain, our fencing options are perfect for any home or business.",
    url: "/products?menu=Fencing&submenu=Bamboo",
  },
  {
    images: "27.png",
    header: "Cladding",
    description:
      "Transform and protect your building with our premium cladding solutions. Our cladding options, available in materials such as wood, metal, and composite, provide a stylish and durable exterior finish. Designed to enhance insulation, weather resistance, and curb appeal, our cladding products are easy to install and maintain. Ideal for both residential and commercial properties, our cladding ensures your building looks great and stands up to the elements.",
    url: "products?menu=Clading&submenu=Outdoor+Clading",
  },
];
