export const ProjectDataCladding = [
  {
    images: ["28.png", "29.png"],
    header: "Apartments and Malls",
    description:
      "The client wanted a Stereo feeling, a beautiful and easily installed wall panel because they need to finish it in a month. Our co-extruded wallboard SP-WP11 can provide perfect stereo, easy installation, and saving labor costs and time, and is very suitable for privacy and public project.",
    detail: {
      location: "Sofia, Bulgaria",
      time: "September 2020",
      product: "Suprotect Wall Cladding SP-WP11(teak color)",
      quantity: "1000sqm",
    },
  },
  {
    images: ["30.jpg"],
    header: "WPC Great Wall External Wall Cladding -Teak Color",
    description:
      "This is a renovation project of the Hong Kong exterior wall panels. The Great wall panels were installed outside the original stone walls. It upgrades a lot immediately.",
    detail: {
      location: "Hong Kong",
      time: "May 2020",
      product: "SP-WP08",
      quantity: "60㎡",
    },
  },
  {
    images: ["31.jpg"],
    header: "Villa Project in Alberta, Canada",
    description:
      "Honestly, this is my ideal perch, no doubt. The flat structural design with the warm teak color exterior siding gives a homely feel.",
    detail: {
      location: "Canada",
      time: "October 21, 2022",
      product: "Wall Cladding Panel",
      quantity: "187 Square Meters",
    },
  },
];
