export default function ServicesInformationComponent({ Images, Title }) {
   return (
      <div className="p-6 border border-divider w-full flex flex-col gap-4 sm:justify-start justify-center items-center">
         <div>
            <img src={`/assets/section3/${Images}`}></img>
         </div>
         <div>{Title}</div>
      </div>
   );
}
