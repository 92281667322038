import { motion } from "framer-motion";
export default function CirclePaginationComponent({ filled }) {
   return (
      <motion.div
         className={`w-[10px] h-[10px] rounded-[10px] ${
            filled ? "brand-primary" : "bg-[#98A2B3]"
         }`}
      ></motion.div>
   );
}
