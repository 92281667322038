export const ProjectDataDecking = [
      {
            images : ["1_1.webp", "1.webp"],
            header : "Roof Decking Pedestal System",
            description : "It was a 66sqm decking project. Mr. Woo told me that it is the roof of his house and sent me the drawing. With a 2.4 degrees slope. After knowing his demand, we provide him NDMerbau Suprotect decking series and pedestal system. Mr. Woo was delighted with the 3D wood grain, the mixed color of the Suprotect. Also, compared with traditional WPC decking, it is easy-cleaning, more anti-UV, and scratch-resistant. So he chose the teak color. For the pedestal system, we provide the whole system, including aluminum joist, base, and angle adjustment. In this process, we promoted our plan more than 3 times to offer Mr.Woo the best strategy. Finally, we are all very glad to confirm the order! ",
            detail : {
                  location : "California, USA",
                  time : "2018.10",
                  product : "BPS01",
                  quantity : "66sqm"
            }
      },
      {
            images : ["2_1.webp","2_2.webp","2_3.webp","2_4.webp","2.webp"],
            header : "Villa No-Gap Decking Project",
            description : "It was a villa project. The client purchased more than 1000sqm no gap decking for the villa project. The patented no-gap decking is co-extrusion decking. It is easy cleaning, waterproof, anti-UV, scratch-resistant. When a lady walks on it in high heels, it won’t cause any problems. Teak color is hot sales. We have confidence it can help to open market and cater more clients’ demands",
            detail : {
                  location : "Dubai, UAE",
                  time : "2021.4",
                  product : "SPML04",
                  quantity : "1000sqm"
            }
      },
      {
            images : ["3_1.webp","3.webp"],
            header : "Zoo Fireproof Project",
            description : "There is a zoo project in Singapore. It was a public project, so it needed to be fireproof, anti-UV, waterproof. NDMerbau decking fireproof coextrusion decking is a good choice. Walnut color is very similar to pure wood color. Do you like it?",
            detail : {
                  location : "Singapore",
                  time : "2021.7",
                  product : "SPOR02",
                  quantity : "560sqm"
            }
      },
      {
            images : ["4_1.webp","4_2.webp","4_3.webp"],
            header : "Wedding Party Club --Phase I & II",
            description : "This is an outdoor wedding party club. It needs a perfect outdoor floor. Not only does it need to match the tone, but it also needs to be slip-resistant, scratch-resistant, and prevents the heel from getting stuck. NDMerbau suprotect decking can match all requirements well. Nice colors mix, slip-resistant, scratch-resistant, and no gap after installation.",
            detail : {
                  location : "Budapest, Hungary",
                  time : "April 2019(First phase), August 2021(Second Phase)",
                  product : "Suprotect Decking BPS01(teak color)",
                  quantity : "1500sqm"
            }
      },
      {
            images : ["5_1.webp","5_2.webp","5_3.webp"],
            header : "WPC Decking Project in Fiji",
            description : "What material to choose for an outdoor terrace deck? You can always find a lot of references, and wood-plastic composite can always be a choice that will not disappoint you.",
            detail : {
                  location : "Fiji",
                  time : "September 2021",
                  product : "Suprotect Decking SPML02",
                  quantity : "180 square meters"
            }
      },
      {
            images : ["6.webp"],
            header : "WPC Decking Project in Singapore",
            description : "Could you imagine that our decking could be also used around the football field? It would be a good place for you to rest. Outstanding idea!",
            detail : {
                  location : "Singapore",
                  time : "February 2022",
                  product : "WPC Decking SPML02",
                  quantity : "196 square meters"
            }
      },
      {
            images : ["7.webp","7_1.webp"],
            header : "Privacy Fence Project in China",
            description : "NDMerbau patented strong fence makes your garden maintenance-free. You can't miss it！！！",
            detail : {
                  location : "China",
                  time : "September 2021",
                  product : "Privacy Fence",
                  quantity : "100 square meters"
            }
      },{
            images : ["8.webp","8_1.webp"],
            header : "Japen WPC Pergola Project",
            description : "NDMerbau's WPC Pergola with a dedicated motorized sunshade and WPC white floor, what a beautiful Japanese seaside villa.",
            detail : {
                  location : "Japan",
                  time : "February 2022",
                  product : "WPC Pergola",
                  quantity : "30 sets"
            }
      }
]