import { useEffect, useState } from "react";
import { NavbarData } from "../data/NavbarData";
import { useAtom } from "jotai";
import { openModalHamburger } from "../store/modalHamburger";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, ScrollLink } from "react-scroll";
import { useSearchParams } from "react-router-dom";

export default function NavbarComponent() {
  const [openModal, setOpenModal] = useAtom(openModalHamburger);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [Content, setContent] = useState("");

  useEffect(() => {
    if (pathname || searchParams) {
      if(pathname == '/projects'){
         setContent("Projects")
      }else if (pathname == "/products") {
        setContent("Products");
      } else if (pathname == "/") {
        setContent("");
      }
      if (searchParams.get("content")) {
        setContent(searchParams.get("content"));
      }
    }
  }, [pathname, searchParams]);

  const handlingLogoRoute = () => {
    if (pathname == "/") {
      searchParams.delete("content");
      setSearchParams(searchParams);
    } else {
      searchParams.delete("content");
      setSearchParams(searchParams);
      navigate("/");
    }
  };

  const handlingNavbarRoute = (item) => {
    if (pathname == "/") {
      searchParams.set("content", item);
      setSearchParams(searchParams);
    } else {
      navigate("/?content=" + item);
    }
  };

  return (
    <div className="fixed sm:px-[100px] py-[25px] px-[25px] flex justify-between items-center w-full bg-[#F8FAFB] z-50">
      <div className="cursor-pointer" onClick={() => handlingLogoRoute()}>
        <img src="/assets/navbar/logo.png" style={{ width: "150px" }}></img>
      </div>
      <div className="hidden sm:flex gap-6">
        {NavbarData.map((item, idx) => {
          return Content == item ? (
            <div
            key={idx}
              smooth={true}
              className="text-[#8E4B1A] font-semibold cursor-pointer"
              onClick={() => handlingNavbarRoute(item)}
            >
              {item}
            </div>
          ) : (
            <div
              smooth={true}
              className="text-black  cursor-pointer"
              onClick={() => handlingNavbarRoute(item)}
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="sm:hidden flex">
        {!openModal ? (
          <div onClick={() => setOpenModal(true)} className="cursor-pointer">
            <img src="/assets/navbar/hamburger.png"></img>
          </div>
        ) : (
          <div onClick={() => setOpenModal(false)} className="cursor-pointer">
            <img src="/assets/navbar/closebutton.png"></img>
          </div>
        )}
      </div>
    </div>
  );
}
