import { motion } from "framer-motion";
export const AlertComponent = ({ status, message }) => {
   return (
      <>
         <div className="fixed top-[150px] right-0 z-[1000]">
            {status == 200 ? (
               <motion.div
                  className="relative  py-3 px-6 bg-green-400 border-green-400 z-[1000] text-center text-[white] rounded-l-md"
                  initial={{ width: "0px", opacity: 0 }}
                  animate={{ width: "250px", opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                     type: "spring",
                  }}
               >
                  {message}
                  <div className="absolute left-0 top-0 w-[5px] h-full bg-white rounded-l-md"></div>
               </motion.div>
            ) : (
               <motion.div
                  className="relative  py-3 px-6 bg-red-400 border-green-400 z-[1000] text-center text-[white] rounded-l-md"
                  initial={{ width: "0px", opacity: 0 }}
                  animate={{ width: "250px", opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                     type: "spring",
                  }}
               >
                  {message}
                  <div className="absolute left-0 top-0 w-[5px] h-full bg-white rounded-l-md"></div>
               </motion.div>
            )}
         </div>
      </>
   );
};
