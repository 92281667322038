export const TestimonialData = [
   [
      {
         star: 5,
         comment:
            "Absolutely thrilled with my purchase! The punctuality of the delivery was spot on, and the communication throughout the process was excellent. The pricing was very reasonable, and the item description was accurate and detailed. Definitely a 5-star experience!",
         subject: "Grant",
      },
      {
         star: 5,
         comment:" I couldn't be happier with my order. The punctuality of the delivery was impressive, arriving exactly when promised. Communication was clear and timely, and the pricing was fair. The item description matched perfectly with what I received. A solid 5 stars!",
         subject: "Chris",
      },
   ],
   [
      {
         star: 5,
         comment:
            "Fantastic service from start to finish. Delivery was punctual, and the communication was consistent and helpful. The pricing was competitive, and the item description was thorough and precise. I highly recommend this seller, 5 stars!",
         subject: "Red",
      },
      {
         star: 5,
         comment:
            "I'm very satisfied with my purchase! The punctuality of the shipping was perfect, and the communication was very professional. The pricing was affordable, and the item description was very accurate. This deserves a 5-star rating for sure!",
         subject: "Ian",
      },
   ],
   [
      {
         star: 5,
         comment:
            "Excellent experience! The punctuality of the delivery was remarkable, arriving right on time. Communication was prompt and informative, the pricing was great, and the item description was spot on. I’m giving this a full 5-star rating!",
         subject: "Leighton",
      },
     
   ],
];
