import FooterComponent from "../FooterComponent";
import NavbarComponent from "../NavbarComponent";
import { openModalHamburger } from "../../store/modalHamburger";
import HamburgerMenuComponent from "../HamburgerMenuComponent";
import { useAtom } from "jotai";
import { NavbarData } from "../../data/NavbarData";
import { createBrowserHistory } from "history";
import { ScrollLink } from "react-scroll";
import { Router } from "react-router-dom";
import { AlertComponent } from "../AlertComponent";
import { alertSendEmail } from "../../store/alertSendEmail";
import { AnimatePresence } from "framer-motion";
import ModalDetailProject from "../ModalDetailProjectComponent";
import { modalDetailProject } from "../../store/modalDetail";
import ModalImagesComponent from "../ModalImagesComponent";
import { modalImages } from "../../store/modalImages";

const history = createBrowserHistory();

export default function LayoutComponent({ children }) {
  const [openModal, setOpenModal] = useAtom(openModalHamburger);
  const [alert, setAlert] = useAtom(alertSendEmail);
  const [modalDetail, setModalDetail] = useAtom(modalDetailProject);
  const [modalImage, setModalImages] = useAtom(modalImages);
  return (
    <>
      <div className="flex flex-col">
        <AnimatePresence>
          {modalDetail.isOpen ? (
            <ModalDetailProject Item={modalDetail.item} />
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {modalImage.isOpen ? <ModalImagesComponent /> : null}
        </AnimatePresence>
        <NavbarComponent />
        <AnimatePresence>
          {alert.bool ? (
            <AlertComponent status={alert.status} message={alert.message} />
          ) : null}
        </AnimatePresence>
        {openModal ? (
          <HamburgerMenuComponent data={NavbarData} />
        ) : (
          <div className="relative">
            <div>{children}</div>
            <FooterComponent />
          </div>
        )}
      </div>
    </>
  );
}
