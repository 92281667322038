import emailjs from "@emailjs/browser";
export default async function StoreFormEmail(formData) {
   const response = await emailjs.sendForm(
      "service_eectdqj",
      "template_tjfaox9",
      formData,
      {
         publicKey: "M5yfV6ZX5sofPK-dB",
      }
   );

   return response
}
