import { useAtom } from "jotai";
import ButtonIconComponent from "./ButtonIconComponent";
import { modalDetailProject } from "../store/modalDetail";
import LazyLoadImage from "./LazyLoadImage";

export function CardProjectComponent({
  Header,
  Images,
  Description,
  Variant,
  Path,
  Detail,
}) {

  const [modalDetail, setModalDetail] = useAtom(modalDetailProject);

  const handlingClickDetail = () => {
    let concatImages = [];
    if (Images.length != 0) {
      Images.map((item) => {
        concatImages.push(Path + item);
      });
    }
    setModalDetail({
      isOpen: true,
      item: {
        Header: Header,
        Image: concatImages,
        Detail: Detail,
        Description: Description,
      },
    });
  };
  return (
    <div
      className={`h-[468px] w-full ${
        Variant == "Products" ? "text-black" : "text-white "
      }`}
    >
      <div>
        <LazyLoadImage
          src={
            Path ? Path + Images[0] : `/assets/section2/projects/${Images[0]}`
          }
          className="object-fit w-full h-[364px]"
          alt="Example Image"
        />
      </div>
      <div className="py-5 px-5 w-full flex justify-between items-center">
        <div className="w-[90%]">
          <div className="text-xl font-semibold truncate w-5/6" title={Header}>
            {Header}
          </div>
          <div
            className="text-xs font-medium truncate clear-start w-5/6"
            title={Description}
          >
            {Description}
          </div>
        </div>
        {Detail ? (
          <div className="w-[10%]" onClick={handlingClickDetail}>
            <ButtonIconComponent fill={true} Variant={Variant} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
