import { motion, spring } from "framer-motion";
import { useState } from "react";

export default function ButtonIconComponent({ fill, Variant }) {
   const [onHover, setHover] = useState(false);

   return (
      <>
         <motion.div
            className={`h-[40px]  flex justify-center items-center border ${
               Variant == "Products"
                  ? "border-[#8e4b1a] text-[#8e4b1a]"
                  : "boder-white text-white"
            }w-[40px] cursor-pointer`}
            initial={{
               width: "0px",
               opacity: 0,
            }}
            animate={{
               width: "40px",
               opacity: 1,
            }}
            // transition={{ type: spring }}
            exit={{
               width: "0px",
               opacity: 0,
            }}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            whileHover={{
               backgroundColor: "#8e4b1a",
               color: "white",
               border: "none",
               boxShadow: "0 6px 30px rgba(42, 55, 119, 0.7)",
            }}
         >
            {onHover ? (
               <img src="/assets/iconWhite.png"></img>
            ) : Variant == "Products" ? (
               <img src="/assets/iconBrown.png"></img>
            ) : (
               <img src="/assets/iconWhite.png"></img>
            )}
         </motion.div>
      </>
   );
}
