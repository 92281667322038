export const FooterData = {
  images: "logo.png",
  description:
    "We are direct suppliers for wholesale and retail. From merbau decking to the latest composites, as well as composite fences for interior and exterior, we provide high quality design solutions.",
  navigation: [
    {
      title: "Products",
      url: "/products",
    },
    {
      title: "Projects",
      url: "/projects",
    },
    {
      title: "Contact Us",
      url: "/?content=Contact",
    },
    {
      title: "About Us",
      url: "/?content=About+Us",
    },
  ],
  company: [
    {
      images: "company.png",
      description: "2/7 Haydock Street. Forrestdale. WA 6112",
    },
    {
      images: "email.png",
      description: "nd.merbaucomposite@gmail.com",
    },
    {
      images: "telp.png",
      description: "0424570999",
    },
  ],
  socialMedia: [{
    images: "insta.png",
    url : "https://www.instagram.com/nd.merbau_composite/"
  }],
};
