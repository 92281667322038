import React, { useState, useEffect } from "react";

export default function LazyLoadImage({ src, alt, className }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className={`relative ${className}`}>
      {!isLoaded && (
        <div className="absolute inset-0 bg-gray-400 animate-pulse"></div>
      )}
      <img
        src={src}
        alt={alt}
        className={`transition-opacity duration-500 ease-in-out w-full h-full flex-shrink-0 object-cover ${
          isLoaded ? "opacity-100" : "opacity-0"
        }`}
        onLoad={handleLoad}
      />
    </div>
  );
}
