import { AnimatePresence, motion, spring } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CardProductComponent({ Images, Header, Description, Url }) {
   const [onHover, setOnHover] = useState(false);
   const navigate = useNavigate();

   return (
      <div className="p-[24px] flex flex-col gap-[24px] w-full  brand-divider h-[267px] relative">
         <div>
            <img
               src={`/assets/section1/products/${Images}`}
               className="w-full object-cover h-[80px] object-center"
            ></img>
         </div>
         <div className="flex flex-col gap-2">
            <div className="text-xl font-medium">{Header}</div>
            <div className="text-secondary-md line-clamp-2" title={Description}>{Description}</div>
         </div>

         <AnimatePresence>
            {onHover ? (
               <motion.div
                  className="hidden md:flex absolute h-[40px]  bottom-0 left-0 flex justify-center items-center border-t border-r border-l border-b-transparent  border-[#8e4b1a]  cursor-pointer gap-3 text-[white] bg-[#8e4b1a]"
                  onHoverEnd={() => setOnHover(false)}
                  onClick={() => navigate(Url)}
                  initial={{
                     width: "0px",
                     opacity: 0,
                     backgroundColor: "#8e4b1a",
                  }}
                  animate={{
                     width: "108px",
                     opacity: 1,
                     backgroundColor: "#8e4b1a",
                  }}
                  transition={{ type: spring }}
                  exit={{
                     width: "0px",
                     opacity: 0,
                     backgroundColor: "#8e4b1a",
                  }}
                  whileHover={{ backgroundColor: "#723C15" }}
               >
                  <motion.div
                     initial={{
                        opacity: 0,
                     }}
                     animate={{
                        opacity: 1,
                     }}
                     exit={{
                        opacity: 0,

                     }}
                     transition={{ type: spring, duration: 3 }}
                     className="flex gap-3  items-center justify-items-center"
                  >
                     <div className="text-[12px]">Show All</div>
                     <img src="/assets/iconWhite.png"></img>
                  </motion.div>
               </motion.div>
            ) : null}
         </AnimatePresence>
        <div className="flex justify-end w-full absolute bottom-0 left-0">
         <motion.div
                                   className="md:hidden flex h-[40px] flex justify-center items-center border-t border-r border-l border-b-transparent  border-[#8e4b1a]  cursor-pointer gap-3 text-[white] bg-[#8e4b1a]"
                                   onHoverEnd={() => setOnHover(false)}
                                   onClick={() => navigate(Url)}
                                   initial={{
                                      width: "0px",
                                      opacity: 0,
                                      backgroundColor: "#8e4b1a",
                                   }}
                                   animate={{
                                      width: "108px",
                                      opacity: 1,
                                      backgroundColor: "#8e4b1a",
                                   }}
                                   transition={{ type: spring }}
                                   exit={{
                                      width: "0px",
                                      opacity: 0,
                                      backgroundColor: "#8e4b1a",
                                   }}
                                   whileHover={{ backgroundColor: "#723C15" }}
                                >
                                   <motion.div
                                      initial={{
                                         opacity: 0,
                                      }}
                                      animate={{
                                         opacity: 1,
                                      }}
                                      exit={{
                                         opacity: 0,

                                      }}
                                      transition={{ type: spring, duration: 3 }}
                                      className="flex gap-3  items-center justify-items-center"
                                   >
                                      <div className="text-[12px]">Show All</div>
                                      <img src="/assets/iconWhite.png"></img>
                                   </motion.div>
                                </motion.div>
        </div>

         {!onHover ? (
            <motion.div
               className="hidden md:flex  absolute h-[40px]  bottom-0 left-0 flex justify-center items-center border-t border-r border-l border-b-transparent  border-[#8e4b1a] w-[40px] cursor-pointer"
               onHoverStart={() => setOnHover(true)}
               onHoverEnd={() => setOnHover(false)}
               initial={{
                  width: "0px",
                  opacity: 0,
               }}
               animate={{
                  width: "40px",
                  opacity: 1,
               }}
               transition={{ type: spring }}
               exit={{
                  width: "0px",
                  opacity: 0,
               }}
            >
               <img src="/assets/iconBrown.png"></img>
            </motion.div>
         ) : null}
      </div>
   );
}
