export const AboutData = {
   images: "image.png",
   title: "Let's get to know about NDMerbau",
   description:
      "ND Merbau&Composite is a family business founded in 2021. We are a direct supplier of merbau decking, half-rafted bamboo, and various other composite products. We are committed to providing high quality products at competitive prices. Customer satisfaction is our top priority.",
   services: [
      {
         images: "images1.png",
         title: "Competitive prices",
      },
      {
         images: "images2.png",
         title: "High Quality",
      },
      {
         images: "images3.png",
         title: "Customer Satisfaction",
      },
   ],
};
