import { modalDetailProject } from "../store/modalDetail";
import { useAtom } from "jotai";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import LazyLoadImage from "./LazyLoadImage";

export default function ModalDetailProject({ Item }) {
  const [modalDetail, setModalDetail] = useAtom(modalDetailProject);
  const [isTruncate, setTruncate] = useState(true);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    if (window.innerWidth < 640) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    checkScreenSize(); // Check initial size
    window.addEventListener("resize", checkScreenSize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", checkScreenSize); // Clean up event listener on unmount
    };
  }, []);

  return (
    <div className="fixed w-full h-full flex justify-center items-center  z-[1000]">
      <div
        className="relative bg-black bg-opacity-50 w-full h-full z-[50] cursor-pointer flex justify-center items-center"
        onClick={() => setModalDetail({ isOpen: false, item: null })}
      ></div>
      <motion.div
        className="absolute z-[100] bg-white sm:max-w-5xl w-4/5 sm:h-fit h-[600px] rounded-md p-4 flex flex-col gap-4 overflow-auto mx-3"
        initial={{ opacity: 0, y: "100px" }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, y: "100px" }}
      >
        <div className="flex w-full justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-7 cursor-pointer"
            onClick={() => setModalDetail({ isOpen: false, item: null })}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
        <div className="flex flex-col gap-3">
          <div className="font-medium">{Item.Header}</div>
          <div>
            <div
              className={`text-sm opacity-90 sm:line-clamp-none ${
                isTruncate ? "line-clamp-3" : "line-clamp-none "
              }`}
            >
              {Item.Description}
            </div>
            {isSmallScreen ? (
              <div
                className="underline text-blue-600 text-sm cursor-pointer"
                onClick={() => setTruncate(!isTruncate)}
              >
                {isTruncate ? "View more" : "View less"}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="">Project detail</div>
          <div className="flex sm:flex-row flex-col sm:gap-5 gap-3">
            <div className="flex flex-col gap-3 flex-1">
              <div className="flex justify-between text-sm">
                <div className="font-medium basis-1/5">Location</div>
                <div className="text-sm opacity-90 basis-4/5 text-end">
                  {Item.Detail.location}
                </div>
              </div>
              <div className="flex justify-between text-sm">
                <div className="font-medium basis-1/5">Time</div>
                <div className="text-sm opacity-90 basis-4/5 text-end">
                  {Item.Detail.time}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 flex-1">
              <div className="flex justify-between text-sm">
                <div className="font-medium basis-1/5">Product</div>
                <div className="text-sm opacity-90 basis-4/5 text-end">
                  {Item.Detail.product}
                </div>
              </div>
              <div className="flex justify-between text-sm">
                <div className="font-medium basis-1/5">Quantity</div>
                <div className="text-sm opacity-90 basis-4/5 text-end">
                  {Item.Detail.quantity}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-3">
          <div className="">Project images</div>
          <div className="flex gap-2 w-full overflow-x-auto flex-shrink-0">
            {Item.Image.map((item) => {
              return (
                <LazyLoadImage
                  src={item}
                  key={item}
                  className={`h-[200px] ${
                    Item.Image.length == 1
                      ? "w-full"
                      : "w-[500px]  flex-shrink-0"
                  } object-center !object-cover cursor-all-scroll flex-shrink-0`}
                />
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
