import { motion } from "framer-motion";

export default function TestimonialComponent({ Star, Comment, Subject, Idx }) {
  return (
    <motion.div
      className="p-4 sm:w-[700px] w-full bg-white text-start rounded-sm h-[254px] sm:mt-0 mt-3 flex justify-center items-center"
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: "100px", opacity: 0 }}
      transition={{
        delay: Idx * 0.2,
      }}
      key={Comment}
    >
      <div>
        <div className="px-[10%] flex gap-3">
          {Array(5)
            .fill("")
            .map((item, idx) => {
              if (idx < Star) {
                return <img src="/assets/starfill.png" key={idx}></img>;
              } else {
                return <img src="/assets/staroutline.png" key={idx}></img>;
              }
            })}
        </div>
        <div className="flex gap-2 mt-4">
          <div className="relative w-[10%]  text-[72px]">
            <div className="absolute top-0 left-0  h-fit">
              <img src="/assets/kutipatas.png"></img>
            </div>
          </div>
          <div className="w-[80%] flex items-center">{Comment}</div>
          <div className=" flex items-end w-[10%]">
            <div className="text-[72px] ">
              <img src="/assets/kutipbawah.png"></img>
            </div>
          </div>
        </div>
        <div className="w-full flex mt-3">
          <div className="w-[10%] "></div>
          <div className="w-[80%]">{Subject}</div>
          <div className="w-[10%] "></div>
        </div>
      </div>
    </motion.div>
  );
}
