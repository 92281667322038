export const ProductDetailInformation = [
  {
    menu: "Decking",
    submenu: [
      {
        menu: "Merbau",
        images: ["1.JPG", "2.JPG", "3.JPG", "4.JPG"],
        description:
          "Merbau timber is a premium hardwood that is highly durable and long lasting. It is pleasurable to work with and has a red-brown finish that becomes even more stunning with age. Merbau material is very stable and has low shrinkage and expansion rate.",
        spec: [
          ["Width options", ["90mm", "140mm"]],
          ["Thickness", ["19mm"]],
          ["Length options", ["range from 1.8m - 5.7m"]],
          ["Not pre-oiled"],
          ["Kiln dried"],
        ],
        advantages: [
          "Resistant  to termites",
          "Very durable",
          "Bushfire resistant",
          "Suitable for outdoor or indoor furniture",
        ],
        install: [],
      },
      {
        menu: "Composite",
        images: ["5.png", "6.png", "7.png", "8.png", "9.png"],
        description:
          "Composite Decking, also known as Wood Plastic Composite Decking, is the new form of decking system used in the world today. It is a better alternative to wood and lasts even longer with a very low amount spent on maintenance. It is the most popular and most resourceful form of decking used in the timber market today.",
        spec: [
          ["Width", ["140mm"]],
          ["Thickness", ["22mm"]],
          ["Length options", ["2.2m", "3.6m", "5.4m"]],
          ["Durable and strong"],
        ],
        advantages: [
          "Waterproof",
          "Easy to install",
          "Fire resistant",
          "Very easy to clean",
        ],
        install: ["install_decking_composite.png"],
      },
    ],
  },
  {
    menu: "Fencing",
    submenu: [
      {
        menu: "Bamboo",
        images: [
          "10.webp",
          "11.JPG",
          "12.JPG",
          "13.JPG",
          "14.JPG",
          "15.JPG",
          "16.JPG",
        ],
        description:
          "Single sided bamboo panel that is easy to install yet strong and durable. It requires minimal maintenance and adds beauty to your backyard.",
        spec: [
          ["Height options", ["1.8m", "2.0m", "2.2m", "2.4m"]],
          ["Half Raft Bamboo Panel"],
          ["Dark Brown Color"],
        ],
        advantages: [],
        install: [],
      },
      {
        menu: "Composite",
        images: ["17.png", "18.png", "19.png", "20.png", "21.png", "22.png"],
        description:
          "Composite screening made from high quality composite wood plastic material. It is easy to install and built to last. Composite screening is an ideal way to add extra privacy to your back or front yard. The texture and look of the boards can often be mistaken for real timber. The great advantage is that it requires much less maintenance. No painting or oiling, just clean with soapy water is enough to keep your composite screens looking their best.",
        spec: [
          ["TwoType", ["Flat", "Slat"]],
          ["Color option", ["Teak", "IPE"]],
          ["Width", ["25mm"]],
          ["Length", ["1800mm"]],
          ["Height", ["1800mm"]],
        ],
        advantages: [
          "Waterproof",
          "Maintenance Free",
          "Easy to install",
          "Resistant to insect damage",
        ],
        install: [
          "install_fenching_composite.png",
          "install_fenching_composite2.png",
        ],
      },
    ],
  },
  {
    menu: "Clading",
    submenu: [
      {
        menu: "Outdoor Clading",
        images: ["23.png", "24.png", "25.png", "26.png", "27.png"],
        description:
          "An excellent option for aesthetically pleasing wall cladding with no treatment needed. WPC Wall Cladding is durable and practical to use. Composite timber cladding has become increasingly popular in the construction and architectural industries due to its exceptional weather-resistant properties. This innovative material combines the natural beauty of wood with the durability of synthetic components, creating a versatile and resilient cladding option that can withstand a variety of harsh weather conditions.",
        spec: [
          ["Panel Size", ["222mm x 25mm x 2900mm"]],
          ["Option Color", ["Teak", "IPE", "Antique", "Dark Grey"]],
        ],
        advantages: ["Weather resistant", "Easy to install", "Durable"],
        install: [
          "installcladding.png",
          "installcladding2.png",
          "installcladding3.png",
        ],
      },
      {
        menu: "Indoor Clading",
        images: ["productindoor.png","productindoor2.png","productindoor3.png"],
        description:
          " WPC Interior wall panels are made of wood fibers and plastic which gives not only  the  natural look and feel of wood, but also the strength and resistance of plastic. It is easy to install, require no painting and low maintenance needed.",
        spec: [],
        advantages: [],
        install: ["installationindoor.png"],
      },
    ],
  },
];
