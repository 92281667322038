import NoFoundComponent from "../components/NoFoundComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LayoutComponent from "../components/layout/LayoutComponent";
import ProductPage from "../pages/ProductPage";
import { createBrowserHistory } from "history";
import ProjectPage from "../pages/ProjectPage";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
const history = createBrowserHistory();

export default function LandingRoutes() {
  return (
    <BrowserRouter history={history}>
      <Routes history={history}>
        <Route path="/">
          <Route
            index
            element={
              <ErrorBoundary fallback={<div>test</div>}>
                <LayoutComponent>
                  <ErrorBoundary fallback={<div>test</div>}>
                    <Suspense fallback="test">
                      <HomePage />
                    </Suspense>
                  </ErrorBoundary>
                </LayoutComponent>
              </ErrorBoundary>
            }
          />
          <Route
            path="/products"
            element={
              <ErrorBoundary fallback={<div>test</div>}>
                <Suspense fallback="test">
                  <LayoutComponent>
                    <ProductPage />
                  </LayoutComponent>
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/projects"
            element={
              <ErrorBoundary fallback={<div>test</div>}>
                  <Suspense fallback="test">
                <LayoutComponent>
                  <ProjectPage />
                </LayoutComponent>
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route path="*" element={<NoFoundComponent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
