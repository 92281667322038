import NavbarComponent from "../components/NavbarComponent";
import CardProductComponent from "../components/CardProductComponent";
import { ProductData } from "../data/ProductData";
import { ProjectData } from "../data/ProjectData";
import { CardProjectComponent } from "../components/CardProjectComponent";
import TestimonialComponent from "../components/TestimonialComponent";
import { TestimonialData } from "../data/TestimonialData";
import ButtonBackPaginationComponent from "../components/ButtonBackPaginationComponent";
import ButtonNextPaginationComponent from "../components/ButtonNextPaginationComponent";
import { useEffect, useState } from "react";
import CirclePaginationComponent from "../components/CirclePaginationComponent";
import { AboutData } from "../data/AboutData";
import ServicesInformationComponent from "../components/ServicesInformationComponent";
import ButtonComponent from "../components/ButtonComponent";
import { motion, useScroll, useTransform, useInView } from "framer-motion";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import StoreFormEmail from "../utils/storeFormEmail";
import { useAtom } from "jotai";
import { alertSendEmail } from "../store/alertSendEmail";
import { Spinner } from "@nextui-org/spinner";
import { ProjectDataDecking } from "../data/ProjectDataDecking";
import { ProjectDataFenching } from "../data/ProjectDataFenching";
import { getRandomIndex } from "../utils/getRandomIndex";

import { useRef } from "react";
import { ProjectDataCladding } from "../data/ProjectDataCladding";
import LazyLoadImage from "../components/LazyLoadImage";

function Section({ children, className }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      ref={ref}
      className={className}
      style={{
        translateY: isInView ? "0px" : "100px",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
    >
      {children}
    </section>
  );
}

export default function HomePage() {
  const [page, setPage] = useState(0);
  const [hoverShowAllproject, setHoverShowAllproject] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [alert, setAlert] = useAtom(alertSendEmail);
  const [loading, setLoading] = useState(false);
  const [idxFenching, setIdxFenching] = useState(0);
  const [idxDecking, setIdxDecking] = useState(0);
  const [idxCladding, setIdxCladding] = useState(0);
  const reference = useRef(null);
  const form = useRef();

  const isInView = useInView(reference, { once: true });
  const navigate = useNavigate();

  const AboutUsSection = useRef(null);
  const ContactSection = useRef(null);
  const HomeSection = useRef(null);

  const scrollToContact = () => {
    if (ContactSection.current) {
      ContactSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAboutUs = () => {
    if (AboutUsSection.current) {
      AboutUsSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHome = () => {
    if (HomeSection.current) {
      HomeSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (searchParams.get("content") == "Contact") {
      scrollToContact();
    } else if (searchParams.get("content") == "About Us") {
      scrollToAboutUs();
    } else if (searchParams.get("content") == "Products") {
      navigate("/products");
    } else if (searchParams.get("content") == "Projects") {
      navigate("/projects");
    } else {
      scrollToHome();
    }
  }, [searchParams]);

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await StoreFormEmail(form.current);

      if (response.status === 200) {
        setAlert({
          bool: true,
          status: 200,
          message: "Successfully to send email",
        });

        setTimeout(() => {
          setAlert({
            bool: false,
            status: null,
            message: null,
          });
          setLoading(false);
        }, 2000);
      } else {
        setAlert({
          bool: true,
          status: 500,
          message: "Failed to send email, please send email manualy",
        });
        setTimeout(() => {
          setAlert({
            bool: false,
            status: null,
            message: null,
          });
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      setAlert({
        bool: true,
        status: 500,
        message: "Failed to send email, please send email manualy",
      });
      setTimeout(() => {
        setAlert({
          bool: false,
          status: null,
          message: null,
        });
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    // const interval = setInterval(() => {
    const idxFenching = getRandomIndex(ProjectDataFenching);
    const idxDecking = getRandomIndex(ProjectDataDecking);
    const idxCladding = getRandomIndex(ProjectDataCladding);
    setIdxFenching(idxFenching);
    setIdxDecking(idxDecking);
    setIdxCladding(idxCladding);
    // }, 30000); // 30.000 milidetik = 30 detik

    // Membersihkan interval ketika komponen dibongkar
    // return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section ref={HomeSection}>
        <div className="md:px-[100px] pt-[50px] px-[25px] flex flex-col gap-[64px]">
          <motion.div className="md:flex gap-6 mt-[64px] items-center justify-between">
            <motion.div
              className="display-2xl typography-light-heading md:w-[50%] w-full"
              initial={{ opacity: 0, y: "20px" }}
              animate={{ opacity: 1, y: "0px" }}
              transition={{
                delay: 0.1, // Adjust delay as needed
                type: "spring", // Or "tween" if preferred
              }}
            >
              Direct supplier for merbau, bamboo, and composite products
            </motion.div>
            <motion.div
              className="text-lg typography-light-subheading md:w-[50%] w-full  flex justify-end"
              initial={{ opacity: 0, y: "20px" }}
              animate={{ opacity: 1, y: "0px" }}
              transition={{
                delay: 0.1, // Adjust delay as needed
                type: "spring", // Or "tween" if preferred
              }}
            >
              <p className="md:w-[60%] w-full md:mt-0 mt-4">
                We provide high quality design solutions, from merbau decking to
                the latest composites, as well as composite fences for interior
                and exterior.
              </p>
            </motion.div>
          </motion.div>
        </div>
        <motion.div
          className="flex flex-col md:flex-row gap-3 mt-[64px] w-full"
          initial={{ opacity: 0, y: "20px" }}
          animate={{ opacity: 1, y: "0px" }}
          transition={{
            delay: 0.15, // Adjust delay as needed
            type: "spring", // Or "tween" if preferred
          }}
        >
          <LazyLoadImage
            src={`/assets/section1/image1_1.png`}
            className="md:w-[80%] w-full md:h-[600px] h-[403px] object-cover"
            alt="Example Image"
          />
          <LazyLoadImage
            src="/assets/section1/image2_1.jpg"
            className="md:w-[20%] w-full object-cover h-[130px] md:h-[600px]"
            alt="Example Image"
          />
        </motion.div>
      </section>
      <section ref={reference} id="Section-2">
        <div
          className="md:px-[100px] px-[25px]  flex md:flex-row flex-col  mt-[100px] items-start justify-start mb-[100px]"
          id="hallo"
          style={{
            opacity: isInView ? 1 : 0,
            transform: isInView ? "none" : "translateY(50px)",
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <div className="md:w-[10%] w-full text-lg font-medium">
            Our products
          </div>
          <div className="md:w-[90%] w-full flex flex-col gap-[56px] items-start justify-start">
            <div className="display-sm font-medium md:w-[600px] flex justify-start items-start">
              We offer a variety of customized solutions to make your wishes
              come true.
            </div>
            <div className="md:grid grid-cols-3 w-full">
              {ProductData.map((item, idx) => {
                return (
                  <CardProductComponent
                    Images={item.images}
                    Header={item.header}
                    Description={item.description}
                    key={idx}
                    Url={item.url}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <Section className="bg-[#2B1708] md:p-[100px] p-[25px] text-white gap-3">
        <motion.div>
          <div>
            <div className="flex md:flex-row flex-col md:gap-0 gap-6 justify-between md:items-end items-start ">
              <div>
                <div className="text-lg font-medium ">Our Projects</div>
                <div className="display-sm font-medium w-[80%]">
                  Our products have been applied to various projects
                </div>
              </div>
                <motion.div
                  className="border border-white w-[185px] flex justify-center items-center text-white h-[48px] cursor-pointer hover:bg-primary hover:border-none"
                  onClick={() => navigate("/projects")}

                >
                  <div className="flex gap-3 items-center justify-center">
                    <div>Show All Projects</div>
                    <div>
                      <img src="/assets/iconWhite.png"></img>
                    </div>
                  </div>
                </motion.div>
            </div>
          </div>
          <div className="md:grid grid-cols-3 mt-[56px] gap-6">
            <CardProjectComponent
              Images={ProjectDataDecking[idxDecking].images}
              Header={ProjectDataDecking[idxDecking].header}
              Description={ProjectDataDecking[idxDecking].description}
              Detail={ProjectDataDecking[idxDecking].detail}
              Path={`/assets/projects-assets/decking/`}
            />
            <CardProjectComponent
              Images={ProjectDataFenching[idxFenching].images}
              Header={ProjectDataFenching[idxFenching].header}
              Description={ProjectDataFenching[idxFenching].description}
              Detail={ProjectDataFenching[idxFenching].detail}
              Path={`/assets/projects-assets/fenching/`}
            />
            <CardProjectComponent
              Images={ProjectDataCladding[idxCladding].images}
              Header={ProjectDataCladding[idxCladding].header}
              Description={ProjectDataCladding[idxCladding].description}
              Detail={ProjectDataCladding[idxCladding].detail}
              Path={`/assets/projects-assets/cladding/`}
            />
          </div>
        </motion.div>
      </Section>
      <Section className="text-center bg-[#F8FAFB] md:px-[100px] px-[25px] mt-[128px] mb-[128px]">
        <motion.div>
          <div className="text-lg font-medium">Testimonial</div>
          <div className="display-sm font-medium">Let's hear what they say</div>
        </motion.div>
        <motion.div className="justify-center md:flex flex-cols  gap-6 items-center mt-[72px] mb-[72px]">
          {TestimonialData[page].map((items, idx) => {
            return (
              <TestimonialComponent
                Star={items.star}
                Subject={items.subject}
                Comment={items.comment}
                key={items.comment}
                Idx={idx}
              />
            );
          })}
        </motion.div>
        <div className="w-full flex justify-center gap-6 items-center">
          <ButtonBackPaginationComponent
            setBackPage={() => setPage(page - 1)}
            disable={page == 0 ? true : false}
          />
          <div className="flex gap-3">
            {Array(TestimonialData.length)
              .fill("")
              .map((item, idx) => {
                if (page == idx) {
                  return <CirclePaginationComponent filled={true} />;
                } else {
                  return <CirclePaginationComponent filled={false} />;
                }
              })}
          </div>
          <ButtonNextPaginationComponent
            setNextPage={() => setPage(page + 1)}
            disable={page == TestimonialData.length - 1 ? true : false}
          />
        </div>
      </Section>
      <div ref={AboutUsSection}>
        <Section className="md:py-[100px] md:pr-[100px]" id="AboutUs">
          {
            <div className="flex md:flex-row flex-col gap-3">
              <div className="md:w-[30%] w-full">
                <img
                  src={`/assets/section3/${AboutData.images}`}
                  className="md:w-[700px] w-full md:h-[560px] h-[378px] object-fit"
                ></img>
              </div>
              <div className="flex items-center md:p-10 p-[25px] md:w-[70%]">
                <div className="flex flex-col gap-3">
                  <div className="text-lg font-medium">About Us</div>
                  <div className="flex flex-col gap-6">
                    <div className="display-sm font-medium">
                      {AboutData.title}
                    </div>
                    <div className="text-lg">{AboutData.description}</div>
                  </div>
                  <div className="flex md:flex-row flex-col gap-3 w-full mt-4">
                    {AboutData.services.map((item) => {
                      return (
                        <ServicesInformationComponent
                          Images={item.images}
                          Title={item.title}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          }
        </Section>
      </div>
      <section ref={ContactSection}>
        <Section className="md:py-[100px] py-[25px] md:px-[100px] px-[25px] flex md:flex-row flex-col gap-12">
          <div className="md:w-[50%] w-full flex flex-col gap-3  md:border-r border-[#D0D5DD]">
            <div className="text-lg font-medium">Contact Us</div>
            <div className="display-md font-medium max-w-lg">
              Let's find out more complete information about products and
              projects directly
            </div>
          </div>
          <form
            onSubmit={formSubmit}
            ref={form}
            className="md:w-[50%] w-full flex flex-col gap-3"
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="text-xs font-medium">Name</div>
                <div>
                  <input
                    type="text"
                    className="h-[46px] border border-[#D0D5DD] w-full  p-4"
                    placeholder="Your name"
                    name="user_name"
                    required={true}
                  ></input>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-xs font-medium">Phone number</div>
                <div>
                  <input
                    type="number"
                    className="h-[46px] border border-[#D0D5DD] w-full  p-4"
                    placeholder="Your phone number"
                    name="user_phone"
                    required={true}
                  ></input>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-xs font-medium">Email</div>
                <div>
                  <input
                    type="email"
                    className="h-[46px] border border-[#D0D5DD] w-full  p-4"
                    placeholder="Your email"
                    name="user_email"
                    required={true}
                  ></input>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-xs font-medium">Message</div>
                <div>
                  <textarea
                    className="h-[145px] border border-[#D0D5DD] w-full  p-4"
                    placeholder="Your message"
                    name="message"
                    required={true}
                  ></textarea>
                </div>
              </div>

              <div className="w-full flex gap-6 justify-end items-center">
                {loading ? <Spinner size="sm" color="primary" /> : null}
                <button type="submit">
                  <ButtonComponent Text={"Submit Message"} />
                </button>
              </div>
            </div>
          </form>
        </Section>
      </section>
    </>
  );
}
