export const ProductMenuAndSubmenu = [
  {
    menu: "Decking",
    submenu: ["Merbau", "Composite"],
  },
  {
    menu: "Fencing",
    submenu: ["Bamboo", "Composite"],
  },
  {
    menu: "Clading",
    submenu: ["Outdoor Clading","Indoor Clading"],
  },
];
