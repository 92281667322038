import { useAtom } from "jotai";
import { modalImages } from "../store/modalImages";
import { motion } from "framer-motion";
import { useState } from "react";

export default function ModalImagesComponent() {
  const [modalImage, setModalImages] = useAtom(modalImages);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState(
    modalImages.clickedImages
  );

  return (
    <div className="fixed w-full h-full flex justify-center items-center  z-[1000]">
      <div
        className="relative bg-black bg-opacity-50 w-full h-full z-[50] cursor-pointer flex justify-center items-center"
        onClick={() =>
          setModalImages({ isOpen: false, item: null, clickedImages: "" })
        }
      ></div>
      <motion.div
        className="absolute z-[100] bg-white sm:max-w-5xl w-4/5  h-[600px] rounded-md flex flex-col gap-4 overflow-auto"
        initial={{ opacity: 0, y: "100px" }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, y: "100px" }}
      >
        <motion.img
          src={`/assets/products/${
            selectedImages ? selectedImages : modalImage.clickedImages
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: "spring" }}
          className="w-full h-full flex-shrink-0"
        ></motion.img>
        <div className="absolute top-3 right-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-9 cursor-pointer text-white"
            onClick={() =>
              setModalImages({ isOpen: false, item: null, clickedImages: "" })
            }
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
        <div className="hidden bottom-4 absolute w-full sm:flex justify-center items-center">
          {!isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-9 text-white animate-bounce cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          ) : null}
        </div>
        {isOpen ? (
          <motion.div
            className="absolute bottom-0 w-full h-full gap-3 bg-black bg-opacity-50 flex flex-col justify-end items-end"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: "spring" }}
          >
            <div
              className="w-full flex justify-center items-center"
              onClick={() => setIsOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-9 text-white animate-bounce cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
            <div className="flex w-full h-fit gap-4 p-3 justify-end">
              <div className="flex w-full gap-4 justify-end">
                {modalImage.item ? modalImage.item.map((item, idx) => {
                  return (
                    <motion.img
                      key={item}
                      src={`/assets/products/${item}`}
                      className="rounded-md w-[200px] h-[100px] cursor-pointer"
                      initial={{ opacity: 0, x: "100px" }}
                      animate={{ opacity: 1, x: "0px" }}
                      transition={{
                        delay: idx * 0.3,
                      }}
                      onClick={() => setSelectedImages(item)}
                    ></motion.img>
                  );
                }) : null}
              </div>
            </div>
          </motion.div>
        ) : null}
      </motion.div>
    </div>
  );
}
