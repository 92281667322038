import { ProductSegementData } from "../data/ProductSegmentData";
import { ProjectData } from "../data/ProjectData";
import { CardProjectComponent } from "../components/CardProjectComponent";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ProjectDataDecking } from "../data/ProjectDataDecking";
import { ProjectDataFenching } from "../data/ProjectDataFenching";
import ButtonComponent from "../components/ButtonComponent";
import { Spinner } from "@nextui-org/spinner";
import { motion } from "framer-motion";
import { ProductData } from "../data/ProductData";
import { ProjectDataCladding } from "../data/ProjectDataCladding";

export default function ProjectPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [menu, setMenu] = useState("Decking");
  const [subMenu, setSubMenu] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [data, setData] = useState();
  const [idxDeckingPagination, setIdxDeckingPagination] = useState(6);
  const [idxFenchingPagination, setIdxFenchingPagination] = useState(6);
  const [idxCladdingPagination, setIdxCladdingPagination] = useState(6);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);

  const ProductSection = useRef(null);

  const scrollToProducts = () => {
    if (ProductSection.current) {
      ProductSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (searchParams) {
      if (searchParams.get("menu")) {
        setMenu(searchParams.get("menu"));
        setSearchParams(searchParams);
      }
      if (searchParams.get("submenu")) {
        setSubMenu(searchParams.get("submenu"));
        setSearchParams(searchParams);
      }
      scrollToProducts();
    }
  }, [searchParams]);

  const handlingParams = (item) => {
    setMenu(item);
    searchParams.set("menu", item);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (menu) {
      if (menu == "Decking") {
        setData(ProjectDataDecking);
      } else if (menu == "Fenching") {
        setData(ProjectDataFenching);
      }else if(menu == "Cladding"){
        setData(ProjectDataCladding)
      }else{
        navigate("/notfound")
      }
    }
  }, [menu]);

  const handlingPagination = () => {
    setIsLoadingPagination(true);

    setTimeout(() => {
      setIsLoadingPagination(false);
      if (menu == "Decking") {
        setIdxDeckingPagination(idxDeckingPagination + 6);
      } else if (menu == "Fenching") {
        setIdxFenchingPagination(idxFenchingPagination + 6);
      }
    }, 1000);
  };

  return (
    <motion.section
      className="md:px-[100px] md:pt-[150px] pt-[50px] px-[25px] flex flex-col gap-[64px]"
      ref={ProductSection}
    >
      <section className="mt-[64px]">
        <div className="flex flex-col w-full gap-8">
          <div className="flex gap-6 items-center">
            <div
              className="p-3 border border-grey-200 cursor-pointer hover:bg-[#8e4b1a] hover:text-white"
              onClick={() => navigate("/")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
            </div>
            <div className="display-xs font-medium">Projects</div>
          </div>
          <div className="py-2 px-4 w-full border border-slate-200 flex ">
            <div className="flex lg:w-3/12 w-full">
              {ProductSegementData.map((item, idx) => {
                return item == menu ? (
                  <div className="border border-slate-200 py-3 md:px-6 px-4 w-full brand-primary text-white flex justify-center" key={idx}>
                    {item}
                  </div>
                ) : (
                  <div
                    className="border border-slate-200 py-3 md:px-6 w-full px-4 flex justify-center cursor-pointer"
                    onClick={() => handlingParams(item)}
                    key={idx}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <motion.section
        className={`flex flex-col gap-12 ${menu ? "animate-fade-in" : null}`}
        initial={{ opacity: 0, y: "20px" }}
        animate={{ opacity: 1, y: "0px" }}
      >
        {data ? (
          <div className="flex flex-col gap-6">
            {ProductData.map((item) => {
              if (item.header == menu) {
                return (
                  <>
              
                    <div className="display-2xl ">{item.header}</div>
                    <div className="max-w-lg w-full  text-secondary-md">
                      {item.description}
                    </div>
                  </>
                );
              }
            })}
          </div>
        ) : null}
      </motion.section>

      <motion.section
        className="mt-6 mb-32 "
        initial={{ opacity: 0, y: "20px" }}
        animate={{ opacity: 1, y: "0px" }}
      >
        <div className="text-lg font-semibold">
          Projects ({data ? data.length : null})
        </div>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-[56px] text-black">
          {data
            ? data.map((item, idx) => {
                if (
                  menu == "Decking"
                    ? idx < idxDeckingPagination
                    : menu == "Fenching"
                    ? idx < idxFenchingPagination
                    : menu == "Cladding" ? idx < idxCladdingPagination : null
                ) {
                  return (
                    <CardProjectComponent
                      Images={item.images}
                      Header={item.header}
                      Description={item.description}
                      key={idx}
                      Detail={item.detail}
                      Path={`/assets/projects-assets/${menu.toLowerCase()}/`}
                      Variant={"Products"}
                    />
                  );
                }
              })
            : null}
        </div>
        {isLoadingPagination ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : null}
        {!isLoadingPagination &&
        data &&
        (menu == "Decking"
          ? data.length > idxDeckingPagination
          : menu == "Fenching"
          ? data.length > idxFenchingPagination
          : menu == "Claddding" ? data.length > idxCladdingPagination : null) ? (
          <div className="flex justify-center" onClick={handlingPagination}>
            <ButtonComponent Text={"View All"} />
          </div>
        ) : null}
      </motion.section>
    </motion.section>
  );
}
