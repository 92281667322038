import { useNavigate } from "react-router-dom";
import { FooterData } from "../data/FooterData";
import { motion } from "framer-motion";

export default function FooterComponent() {
  const navigate = useNavigate();
  return (
    <div className="md:px-[100px] md:py-[50px] py-[25px] px-[25px] border border-t-[#D0D5DD] flex  flex-col gap-6 ">
      <div className="flex md:flex-row flex-col md:gap-12  pb-12 border-[#D0D5DD] border-b">
        <div className="md:w-[40%] w-full  md:border-r border-[#D0D5DD]">
          <img src="/assets/navbar/logo.png" className="max-w-40 cursor-pointer" onClick={() => navigate("/")}></img>
          <div className="mt-4 text-lg pr-8">
            We are direct suppliers for wholesale and retail. From merbau
            decking to the latest composites, as well as composite fences for
            interior and exterior, we provide high quality design solutions.
          </div>
        </div>
        <div className="md:w-[60%] w-full flex md:flex-row flex-col gap-6 md:mt-0 mt-6">
          <div className="md:w-[30%] w-full">
            <div className="text-lg font-semibold">NAVIGATION</div>
            <div className="mt-4 flex flex-col gap-3">
              {FooterData.navigation.map((item) => {
                return (
                  <div
                    className="text-md font-medium cursor-pointer"
                    onClick={() => navigate(item.url)}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="md:w-[70%] w-full ">
            <div className="text-lg font-semibold">COMPANY</div>
            <div className="mt-4 flex flex-col gap-3">
              {FooterData.company.map((item) => {
                return (
                  <div className="flex gap-3  items-center">
                    <img
                      src={`/assets/${item.images}`}
                      className="w-[30px] h-[30px] object-cover"
                    />
                    <div className="text-md">{item.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex md:flex-row flex-col md:justify-between justify-center items-center md:gap-0 gap-6">
        <div className="flex gap-6 md:hidden ">
          {FooterData.socialMedia.map((item) => {
            return <img src={`/assets/${item.images}`}  onClick={() => {
                                                                         window.location.href = item.url
                                                                       }}></img>;
          })}
        </div>
        <div className="text-center flex justify-center">
          Copyright © 2024 NDMerbau. All Right Reserved.
        </div>
        <div className="md:flex gap-6 hidden">
          {FooterData.socialMedia.map((item) => {
            return (
              <motion.div
                className="cursor-pointer"
                whileHover={{
                  scale: 1.2,
                }}
                onClick={() => {
                  window.location.href = item.url
                }}
              >
                <img src={`/assets/${item.images}`}></img>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
