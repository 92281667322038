import {motion} from "framer-motion"
export default function ButtonComponent({ Text, Width, Height }) {
   return (
      <motion.div
         className={`h-[${Width ? Width + "px" : "48px"}] w-[${
            Height ? Height + "px" : "155px"
         }] brand-primary flex justify-center items-center text-white py-3 px-6 cursor-pointer`}
         whileHover={{
            scale : 1.05
         }}
      >
         {Text}
      </motion.div>
   );
}
