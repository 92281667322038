export default function ButtonNextPaginationComponent({
   setNextPage,
   disable,
}) {
   return (
      <button
         className={`w-[40px] h-[40px] ${
            disable ? "bg-[#667085] cursor-not-allowed" : "brand-primary"
         } text-white flex justify-center items-center`}
         onClick={setNextPage}
         disabled={disable}
      >
         <img src="/assets/nextarrow.png"></img>
      </button>
   );
}
