export const ProjectDataFenching = [
      {
            images : ["10.webp","10_1.webp"],
            header : "Privacy Project",
            description : "o matter what season it is, Mecofence is one beautiful scenery. It can provide you a Visual enjoyment as well as substantial protection. Both the fence, fence gate, and electronic gate are bought from NDMerbau.",
            detail : {
                  location : "Calgary, Alberta, Canada",
                  time : "April 2021",
                  product : "MecoFence, Privacy Design(dark gray color+sliver structure)",
                  quantity : "150 Meters"
            }
      },
      {
            images : ["11.webp"],
            header : "Villa Garden Fence Silver Aluminum Post",
            description : "The client was doing landscape garden decoration in Canada. He contacted us at the Spring Festival of 2019. The client wanted to develop new products to be more competitive compared to local companies. After a period of communication, the client knew more advantages of our fence and was more interested in cooperation. When the customers returned to China for the Spring Festival, they stopped by our company to visit. During the meeting, the client showed his interest in being our agent. The client ordered two containers at first and became our agent.",
            detail : {
                  location : "Montreal, Canada",
                  time : "August 2020",
                  product : "Privacy Fence, Dark Gray Color Fence Board,",
                  quantity : "1*20ft container"
            }
      },
      {
            images : ["12_1.webp","12_2.webp","12_3.webp"],
            header : "Canada Luxury Garden Project",
            description : "Dark grey WPC fencing is paired with the floor to create a premium pool garden.",
            detail : {
                  location : "Canada",
                  time : "June 2021",
                  product : "Privacy Fence",
                  quantity : "200 meters"
            }
      },
      {
            images : ["13.webp","13_1.webp"],
            header : "Israel Customer's WPC Co-extrusion Fence Inspection Today",
            description : "The co-extruded fence is the product with the fastest growing demand recently, and the fence panels of this batch of Israeli customers have been inspected today and are ready to be shipped.",
            detail : null
      },
      {
            images : ["14.webp","14_1.webp","14_2.webp"],
            header : "Co-extrusion Composite Wood Fences Project In Australi",
            description : "Without fear of humidity, co-extrusion composite wood fences offer the advantages of imperishable, long-lasting, and robust fencing with low maintenance costs.",
            detail : {
                  location : "Gold Coast, Australia",
                  time : "2022.01",
                  product : "Suprotect WPC Privacy Fence（Dark Grey）",
                  quantity : "40sets"
            }
      },
      {
            images : ["16_2.webp","16_1.webp","16.webp"],
            header : "WPC Fence Project In Maldives",
            description : "The WPC fence in white color could be stunning. Check the fence project in the Maldives.",
            detail : {
                  location : "Maldives",
                  time : "June 2021",
                  product : "Privacy Fence",
                  quantity : "200 meters"
            }
      },
      {
            images : ["17_2.webp","17_1.webp","17.webp"],
            header : "WPC Fence Feedback from Italian Customers",
            description : "No matter what style you want to make, Mecofence can meet your requirements.",
            detail : {
                  location : "Italy",
                  time : "March 2022",
                  product : "WPC Fence",
                  quantity : "30 sets"
            }
      },

]