import { ProductSegementData } from "../data/ProductSegmentData";
import { ProjectData } from "../data/ProjectData";
import { CardProjectComponent } from "../components/CardProjectComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ProductDataAll } from "../data/ProductDataAll";
import { ProductMenuAndSubmenu } from "../data/ProductMenuAndSubmenu";
import { ProductDetailInformation } from "../data/ProductDetailInformation";
import { useAtom } from "jotai";
import { modalImages } from "../store/modalImages";
import { ProjectDataFenching } from "../data/ProjectDataFenching";
import { ProjectDataDecking } from "../data/ProjectDataDecking";
import { getRandomIndex } from "../utils/getRandomIndex";
import LazyLoadImage from "../components/LazyLoadImage";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { ProjectDataCladding } from "../data/ProjectDataCladding";

export default function ProductPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [menu, setMenu] = useState();
  const [subMenu, setSubMenu] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [modalImage, setModalImages] = useAtom(modalImages);
  const [idxFenching, setIdxFenching] = useState(0);
  const [idxDecking, setIdxDecking] = useState(0);
  const [idxCladding, setIdxCladding] = useState(0);
  const [segment, setSegment] = useState();

  const ProductSection = useRef(null);

  const scrollToProducts = () => {
    if (ProductSection.current) {
      ProductSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (searchParams) {
      if (searchParams.get("menu")) {
        setMenu(searchParams.get("menu"));
        setSearchParams(searchParams);
      }
      if (searchParams.get("submenu")) {
        setSubMenu(searchParams.get("submenu"));
        setSearchParams(searchParams);
      }
      if (!searchParams.get("menu") && !searchParams.get("submenu")) {
        setMenu(ProductMenuAndSubmenu[0].menu);
        setSubMenu(ProductMenuAndSubmenu[0].submenu[0]);
      }
      scrollToProducts();
    } else {
    }
  }, [searchParams]);

  const handlingParams = (item) => {
    setSegment("product_spesification");
    setMenu(item);
    searchParams.set("menu", item);
    setSearchParams(searchParams);
    const firstSubmenu = ProductMenuAndSubmenu.filter(
      (val) => val.menu == item
    );
    setSubMenu(firstSubmenu[0].submenu[0]);
    searchParams.set("submenu", firstSubmenu[0].submenu[0]);
    setSearchParams(searchParams);
  };

  const handlingParamsSubmenu = (item) => {
    setSegment("product_spesification");
    setSubMenu(item);
    searchParams.set("submenu", item);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (menu && subMenu) {
      try {
        const filteredDataContentMenu = ProductDetailInformation.filter(
          (item) => item.menu == menu
        );

        const resDataSubMenu = filteredDataContentMenu[0].submenu.filter(
          (item) => item.menu == subMenu
        );

        if (searchParams.get("menu") && searchParams.get("submenu")) {
          setMenu(searchParams.get("menu"));
          setSubMenu(searchParams.get("submenu"));
          if (
            ![
              "Merbau",
              "Composite",
              "Bamboo",
              "Outdoor Clading",
              "Indoor Clading",
            ].includes(searchParams.get("submenu"))
          ) {
            navigate("/notfound");
          }
        }

        setData(resDataSubMenu[0]);
      } catch (error) {
        navigate("/notfound");
      }
    }
  });

  useEffect(() => {
    if (data) {
      setSegment(
        data.spec.length != 0
          ? "product_spesification"
          : data.advantages.length != 0
          ? "advantages"
          : "installation"
      );
    }
  }, [data]);

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(count + 1);
  }, [segment]);

  useEffect(() => {
    const interval = setInterval(() => {
      const idxFenching = getRandomIndex(ProjectDataFenching);
      const idxDecking = getRandomIndex(ProjectDataDecking);
      const idxCladding = getRandomIndex(ProjectDataCladding);
      setIdxFenching(idxFenching);
      setIdxDecking(idxDecking);
      setIdxCladding(idxCladding);
    }, 30000); // 30.000 milidetik = 30 detik

    // Membersihkan interval ketika komponen dibongkar
    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className="md:px-[100px] md:pt-[150px]  pt-[50px] px-[25px] flex flex-col gap-[64px] w-full  "
      ref={ProductSection}
    >
      <section className="mt-[64px]">
        <div className="flex flex-col gap-8">
          <div className="flex gap-6 items-center">
            <div
              className="p-3 border border-grey-200 cursor-pointer hover:bg-[#8e4b1a] hover:text-white"
              onClick={() => navigate("/")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
            </div>
            <div className="display-xs font-medium">Products</div>
          </div>
          <div className="border border-slate-200 px-4 flex flex-col gap-2">
            <div className="flex border-b border-slate-200">
              {ProductMenuAndSubmenu.map((item, idx) => {
                return item.menu == menu ? (
                  <div
                    className={`border-l  ${
                      idx + 1 == ProductSegementData.length ? "border-r" : ""
                    } border-slate-200 py-3 px-6 brand-primary text-white`}
                  >
                    {item.menu}
                  </div>
                ) : (
                  <div
                    className={`border-l  ${
                      idx + 1 == ProductSegementData.length ? "border-r" : ""
                    } border-slate-200 py-3 px-6 cursor-pointer`}
                    onClick={() => handlingParams(item.menu)}
                  >
                    {item.menu}
                  </div>
                );
              })}
            </div>
            <div className=" flex">
              {ProductMenuAndSubmenu.map((item, idx) => {
                if (item.menu == menu) {
                  return item.submenu.map((val) => {
                    return val == subMenu ? (
                      <div
                        className={`py-3 px-6 border-b border-[#8e4b1a] text-[#8e4b1a]`}
                      >
                        {val}
                      </div>
                    ) : (
                      <div
                        className={` py-3 px-6 cursor-pointer`}
                        onClick={() => handlingParamsSubmenu(val)}
                      >
                        {val}
                      </div>
                    );
                  });
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-12">
        <div className="flex flex-col gap-6">
          <div>
            <div className="text-lg font-medium">{menu ? menu : null}</div>
            <div className="display-2xl ">{data ? data.menu : null}</div>
          </div>
          <div className="max-w-lg text-secondary-md">
            {data ? data.description : null}
          </div>
        </div>
        <div>
          <div>Products photo</div>
          <div className="relative">
            <div className="w-full h-[350px] overflow-x-auto flex scrollbar-active py-6  gap-2">
              {data &&
                data.images.map((item) => {
                  return (
                    <div
                      key={item}
                      className={`${
                        data.images.length <= 3
                          ? "md:w-full w-[500px] flex-shrink-0 md:flex-shrink"
                          : "w-[500px] flex-shrink-0"
                      }  p-4 border border-gray-200 cursor-pointer relative`}
                      onClick={() =>
                        setModalImages({
                          isOpen: true,
                          item: data.images,
                          clickedImages: item,
                        })
                      }
                    >
                      <LazyLoadImage
                        src={`/assets/products/${item}`}
                        className="w-full object-cover !h-full"
                        alt="Example Image"
                      />
                    </div>
                  );
                })}
            </div>
            {data && data.images.length <= 3 && (
              <div className="absolute bottom-0 h-2 bg-primary w-full md:flex hidden"></div>
            )}
          </div>
        </div>
      </section>
      <section className="flex md:flex-row flex-col md:gap-0 gap-6">
        <div className="md:w-[30%] md:px-4 md:border-r  md:border-gray-200 flex md:flex-col gap-12 overflow-auto">
          {data && data.spec.length != 0 ? (
            <div
              className={`text-lg font-medium  py-2 w-fit cursor-pointer ${
                segment === "product_spesification"
                  ? "text-[#8e4b1a] border-b border-[#8e4b1a]"
                  : "text-gray-400"
              }`}
              onClick={() => setSegment("product_spesification")}
            >
              Product Specification
            </div>
          ) : null}

          {data && data.advantages.length != 0 ? (
            <div
              className={`text-lg text-nowrap font-medium  py-2 w-fit cursor-pointer  ${
                segment === "advantages"
                  ? "text-[#8e4b1a] border-b border-[#8e4b1a]"
                  : "text-gray-400"
              }`}
              onClick={() => setSegment("advantages")}
            >
              Advantages
            </div>
          ) : null}
          {data && data.install.length != 0 ? (
            <div
              className={`text-lg text-nowrap font-medium  py-2 w-fit cursor-pointer  ${
                segment === "installation"
                  ? "text-[#8e4b1a] border-b border-[#8e4b1a]"
                  : "text-gray-400"
              }`}
              onClick={() => setSegment("installation")}
            >
              Installation
            </div>
          ) : null}
        </div>
        <div className="md:w-[70%] md:px-12 flex flex-col gap-12 justify-center">
          <AnimatePresence>
            {segment == "product_spesification" && data
              ? data.spec.map((item, idx) => {
                  if (item.length != 1) {
                    return (
                      <motion.div
                        className="flex gap-6 items-center"
                        initial={{ opacity: 0, y: "100px" }}
                        animate={{ opacity: 1, y: "0px" }}
                        transition={{ delay: idx * 0.2 }}
                      >
                        <div>{item[0]}</div>
                        <div className="flex gap-3 items-center overflow-auto">
                          {item[1].map((val) => {
                            return (
                              <div
                                className="p-4 border border-gray-200"
                                key={val}
                              >
                                {val}
                              </div>
                            );
                          })}
                        </div>
                      </motion.div>
                    );
                  } else {
                    return (
                      <motion.div
                        className="flex gap-3"
                        initial={{ opacity: 0, y: "100px" }}
                        animate={{ opacity: 1, y: "0px" }}
                        transition={{ delay: idx * 0.2 }}
                      >
                        <div>{item[0]}</div>
                      </motion.div>
                    );
                  }
                })
              : null}
          </AnimatePresence>

          <AnimatePresence>
            {segment == "advantages" && data.advantages.length != 0 ? (
              <div className="flex md:flex-row flex-col gap-5 ">
                {data.advantages.map((item, idx) => {
                  return (
                    <motion.div
                      className="p-4 border border-gray-200 md:text-start text-center"
                      initial={{ opacity: 0, y: "100px" }}
                      animate={{ opacity: 1, y: "0px" }}
                      transition={{ delay: idx * 0.2 }}
                    >
                      {item}
                    </motion.div>
                  );
                })}
              </div>
            ) : null}
          </AnimatePresence>

          <AnimatePresence>
            {segment == "installation" && data.install.length != 0 ? (
              <div className="flex  flex-col gap-5 ">
                {data.install.map((item, idx) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, y: "100px" }}
                      animate={{ opacity: 1, y: "0px" }}
                      transition={{ delay: idx * 0.2 }}
                    >
                      <LazyLoadImage
                        src={"/assets/products/" + item}
                        className="w-full object-cover"
                        alt="Example Image"
                      />
                    </motion.div>
                  );
                })}
              </div>
            ) : null}
          </AnimatePresence>
        </div>
      </section>

      <AnimatePresence>
        {segment == "advantages" ||
        segment == "product_spesification" ||
        segment == "installation" ? (
          <motion.div
            className="mt-6 mb-32 "
            initial={{ opacity: 0, y: "100px" }}
            animate={{ opacity: 1, y: "0px" }}
            transition={{ duration: 0.8, type: "spring" }}
          >
            <motion.div
              key={count}
              className="text-lg font-semibold"
              initial={{ opacity: 0, y: "100px" }}
              animate={{ opacity: 1, y: "0px" }}
              transition={{ duration: 0.8, type: "spring" }}
            >
              Materials Implementation
            </motion.div>
            <motion.div
              className="md:grid grid-cols-3 mt-[56px] text-black gap-6"
              key={segment}
              initial={{ opacity: 0, y: "100px" }}
              animate={{ opacity: 1, y: "0px" }}
              transition={{ duration: 0.8, type: "spring" }}
            >
              {ProjectDataDecking ? (
                <CardProjectComponent
                  Images={ProjectDataDecking[idxDecking].images}
                  Header={ProjectDataDecking[idxDecking].header}
                  Description={ProjectDataDecking[idxDecking].description}
                  Detail={ProjectDataDecking[idxDecking].detail}
                  Path={`/assets/projects-assets/decking/`}
                  Variant={"Products"}
                />
              ) : null}
              {ProjectDataFenching ? (
                <CardProjectComponent
                  Images={ProjectDataFenching[idxFenching].images}
                  Header={ProjectDataFenching[idxFenching].header}
                  Description={ProjectDataFenching[idxFenching].description}
                  Detail={ProjectDataFenching[idxFenching].detail}
                  Path={`/assets/projects-assets/fenching/`}
                  Variant={"Products"}
                />
              ) : null}
              {ProjectDataCladding ? (
                <CardProjectComponent
                  Images={ProjectDataCladding[idxCladding].images}
                  Header={ProjectDataCladding[idxCladding].header}
                  Description={ProjectDataCladding[idxCladding].description}
                  Detail={ProjectDataCladding[idxCladding].detail}
                  Path={`/assets/projects-assets/cladding/`}
                  Variant={"Products"}
                />
              ) : null}
            </motion.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </section>
  );
}
