import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { openModalHamburger } from "../store/modalHamburger";

export default function HamburgerMenuComponent({ data }) {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [, setOpenModal] = useAtom(openModalHamburger);

  return (
    <motion.div
      className="w-full h-screen bg-[#F8FAFB] flex flex-col gap-6 items-center pt-6 sm:0 mt-[80px] z-[1000]"
      key={data}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {data.map((item) => {
        return (
          <button
            key={item}
            className="text-md font-medium"
        
            onClick={(event) => {
              event.preventDefault();
              setSearchParams({ content: item });
              setOpenModal(false);
              if (item == "Products" || item == "Projects") {
                navigate(`/${item}`, { replace: true });
              } else {
                navigate("/?content=" + item);
              }
            }}
          >
            {item}
          </button>
        );
      })}
    </motion.div>
  );
}
